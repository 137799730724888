/* eslint-disable no-underscore-dangle */ /* eslint-disable no-console */
<template>
  <div class="blog-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <h3>Blog</h3>
      <div class="widget">
        <div class="blog-content">
          <b-form
            v-promise-btn="{ action: 'submit' }"
            @submit.prevent="saveBlogs"
            @reset.prevent="resetForm()"
          >
            <div>
              <div class="font-18 py-3">
                Information
                <span class="font-12">(* Required information)</span>
              </div>
              <!-- title -->
              <div>
                <b-form-group>
                  <label>Title*</label>
                  <b-input
                    v-model="$v.blogData.title.$model"
                    :state="validateState('title')"
                    aria-describedby="input-about-live-feedback"
                    placeholder="Enter Title"
                  ></b-input>
                  <b-form-invalid-feedback id="input-about-live-feedback"
                    >Title is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- author -->
              <div class="pt-2">
                <b-form-group>
                  <label>Author</label>
                  <b-input placeholder="Enter Author" v-model="blogData.author"></b-input>
                </b-form-group>
              </div>
              <!-- blogtype -->
              <div>
                <b-form-group>
                  <label class="font-18">Blog Type*</label>
                  <b-form-select
                    aria-describedby="input-blogType-live-feedback"
                    v-model="$v.blogData.blogType.$model"
                    :state="validateState('blogType')"
                    :options="blogType"
                  ></b-form-select>
                  <b-form-invalid-feedback id="input-blogType-live-feedback"
                    >Blog Type is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- blog position -->
              <div class="pt-2">
                <b-form-group>
                  <label>Blog Position</label>
                  <b-input
                    placeholder="Enter Blog Position"
                    v-model="blogData.blogPosition"
                    type="number"
                  ></b-input>
                </b-form-group>
              </div>

              <!-- home blog -->
              <div class="pt-2">
                Home Blog
                <b-form-checkbox
                  v-model="blogData.homeBlog"
                  name="check-button"
                  switch
                ></b-form-checkbox>
              </div>
              <!-- short Desc -->
              <div class="pt-3">
                <b-form-group>
                  <label>Short Description*</label>
                  <br />
                  <b-form-textarea
                    aria-describedby="input-shortDesc-live-feedback"
                    :state="validateState('shortDesc')"
                    v-model="$v.blogData.shortDesc.$model"
                    id="textarea"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                  <!-- <textarea
                    aria-describedby="input-shortDesc-live-feedback"
                    name
                    cols="109"
                    :state="validateState('shortDesc')"
                    v-model="$v.blogData.shortDesc.$model"
                    rows="5"
                  ></textarea>-->
                  <b-form-invalid-feedback id="input-shortDesc-live-feedback"
                    >Short Description is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- description1 -->
              <div class="pt-2">
                <b-form-group>
                  <label>Description1*</label>

                  <tinymce
                    id="description1"
                    :other_options="options"
                    v-model="blogData.description1"
                  ></tinymce>
                  <div
                    v-if="
                      !$v.blogData.description1.required &&
                        $v.blogData.description1.$error &&
                        $v.blogData.description1.$touch
                    "
                    class="error-text py-1"
                  >
                    Description1 is required
                  </div>
                </b-form-group>
              </div>
              <!-- description1 -->
              <!-- <div class="pt-2">
                <b-form-group>
                  <label>Description2</label>
              <tinymce id="description2" v-model="blogData.description2"></tinymce>-->
              <!-- <ckeditor :editor="editor" v-model="blogData.description2" :config="editorConfig"></ckeditor> -->
              <!-- </b-form-group>
              </div>-->
              <!-- date -->
              <div class="pt-2">
                <b-form-group>
                  <label>Blog Date*</label>
                  <div>
                    <input type="date" v-model="blogData.blogDate" />
                  </div>
                  <div
                    v-if="
                      !$v.blogData.blogDate.required &&
                        $v.blogData.blogDate.$error &&
                        $v.blogData.blogDate.$touch
                    "
                    class="red error-text py-1"
                  >
                    Blog Date is required
                  </div>
                </b-form-group>
              </div>
              <!-- caredImage1 -->
              <div class="pt-2">
                <b-form-group>
                  <label>cardImage*</label>
                  <br />
                  <b-button border-0 class="px-5 upload-btn" @click="$refs.fileInput1.click()"
                    >Upload</b-button
                  >
                  <input
                    ref="fileInput1"
                    type="file"
                    accept="image/jpeg"
                    multiple
                    style="display:none;"
                    @change="onCardChange($event.target.name, $event.target.files)"
                  />
                  <div
                    v-if="
                      !$v.blogData.cardImage.required &&
                        $v.blogData.cardImage.$error &&
                        $v.blogData.cardImage.$touch
                    "
                    class="red error-text py-1"
                  >
                    Card Image is required
                  </div>
                </b-form-group>
              </div>
              <div>
                <b-row gutters>
                  <b-col md="2" cols="4" v-for="(image, key) in cardImg" class="my-2" :key="image">
                    <div>
                      <div>
                        <div class="icon-section">
                          <font-awesome-icon
                            :icon="['fas', 'times']"
                            class="red font-12"
                            @click="removeCard(key)"
                          />
                        </div>
                        <img :src="image" alt="your image" class="img-fluid images ml-3" />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <!-- detailImage -->
              <div class="pt-2">
                <b-form-group>
                  <label>detailImage</label>
                  <br />
                  <b-button border-0 class="px-5 upload-btn" @click="$refs.fileInput2.click()"
                    >Upload</b-button
                  >
                  <input
                    ref="fileInput2"
                    type="file"
                    accept="image/jpeg"
                    multiple
                    style="display:none;"
                    @change="onDetailChange($event.target.name, $event.target.files)"
                  />
                </b-form-group>
              </div>
              <div>
                <b-row gutters>
                  <b-col
                    md="2"
                    cols="4"
                    v-for="(image, key) in detailImg"
                    class="my-2"
                    :key="image"
                  >
                    <div>
                      <div>
                        <div class="icon-section">
                          <font-awesome-icon
                            :icon="['fas', 'times']"
                            class="red font-12"
                            @click="removeDetail(key)"
                          />
                        </div>
                        <img :src="image" alt="your image" class="img-fluid images ml-3" />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <!-- tag -->
              <div class="pt-2">
                <div>
                  <label class="typo__label">Tag</label>
                  <multiselect
                    v-model="blogData.tag"
                    deselect-label="Can't remove this value"
                    multiple
                    label="name"
                    :options="tags"
                    :searchable="true"
                    :allow-empty="false"
                  ></multiselect>
                </div>
              </div>
              <!-- urlslug -->
              <div class="pt-2">
                <b-form-group>
                  <label>urlSlug*</label>
                  <b-input
                    placeholder="Enter Url Slug"
                    aria-describedby="input-urlSlug-live-feedback"
                    :state="validateState('urlSlug')"
                    v-model="$v.blogData.urlSlug.$model"
                  ></b-input>
                  <b-form-invalid-feedback id="input-urlSlug-live-feedback"
                    >Url Slug is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- status -->
              <div>
                <b-form-group>
                  <label class="font-18">Status*</label>
                  <b-form-select
                    placeholder="Enter Url Slug"
                    aria-describedby="input-status-live-feedback"
                    :state="validateState('status')"
                    v-model="$v.blogData.status.$model"
                    :options="status"
                  ></b-form-select>
                  <b-form-invalid-feedback id="input-status-live-feedback"
                    >Status is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
            </div>
            <div v-if="showError" class="error-text pt-2 text-center">{{ message }}</div>
            <div class="d-flex justify-content-center pt-3">
              <div>
                <b-button variant="success" type="submit">Save</b-button>
              </div>
              <div>
                <b-button variant="danger" type="reset" class="ml-5">Cancel</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import headerCustom from "@/components/Header.vue";
import Multiselect from "vue-multiselect";
import service from "@/service/apiservice";
import { validationMixin } from "vuelidate";
import Editor from "@tinymce/tinymce-vue";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    headerCustom,
    Multiselect
    // editor: Editor
  },
  data() {
    return {
      options: {
        // create input element, call modal dialog w
        file_picker_callback: function(cb, value, meta) {
          var urls = "";
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.onchange = function() {
            var file = this.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function() {
              var name = file.name.split(".")[0];
              var blobCache = tinymce.activeEditor;

              const fd = new FormData();

              fd.append("file", file, file.name);
              service.awscoverImageUpload(fd, awsResponse => {
                if (awsResponse.status === 200) {
                  blobCache.insertContent('<img src="' + awsResponse.data.file + '">');
                  urls = awsResponse.data.file;
                } else {
                  this.$toaster.error("Cover Image Not Saved");
                }
              });
              cb("Images", { title: file.name });
              // Provide file and text for the link dialog
              if (meta.filetype == "file") {
                cb(blobInfo.blobUri(), { text: name, target: "_blank" });
              }

              // Provide image and alt text for the image dialog
              if (meta.filetype == "image") {
                cb(blobInfo.blobUri(), { alt: file.name, title: name });
              }

              // Provide alternative source and posted for the media dialog
              if (meta.filetype == "media") {
                cb(blobInfo.blobUri(), {
                  source: blobInfo.blobUri(),
                  poster: "image.jpg"
                });
              }
              reader.readAsDataURL(file);
            };
          };
          input.click();
        }
      },

      // options: {
      //   height: 300,
      //   /* enable automatic uploads of images represented by blob or data URIs*/
      //   automatic_uploads: true,
      //   /*
      // file_picker_types: "image",
      // /* and here's our custom image picker*/
      //   file_picker_callback: function(cb, value, meta) {

      //     var input = document.createElement("input");
      //     input.setAttribute("type", "file");
      //     input.setAttribute("accept", "image/*");
      //     input.onchange = function() {

      //       var file = this.files[0];
      //       var reader = new FileReader();

      //       reader.onload = function() {

      //         var id = "blobid" + new Date().getTime();

      //         var blobCache = tinymce.activeEditor.editorUpload.blobCache;

      //         // var base64 = reader.result.split(",")[1];
      //         // var blobInfo = blobCache.create(id, file, base64);
      //         blobCache.add(file.name);
      //
      //         /* call the callback and populate the Title field with the file name */
      //         cb(id, {
      //           title: file.name
      //         });
      //       };
      //       // reader.readAsDataURL(file);
      //     };
      //     input.click();
      //   }
      // },
      // options: {
      //   height: 300,
      //   /* enable automatic uploads of images represented by blob or data URIs*/
      //   automatic_uploads: true,
      //   /*
      // file_picker_types: "image",
      // /* and here's our custom image picker*/
      //   file_picker_callback: function(cb, value, meta) {
      //     var input = document.createElement("input");
      //     input.setAttribute("type", "file");
      //     input.setAttribute("accept", "image/*");
      //     input.onchange = function() {
      //       var file = this.files[0];
      //       var reader = new FileReader();
      //       reader.onload = function() {
      //         var id = "blobid" + new Date().getTime();
      //         var blobCache = tinymce.activeEditor.editorUpload.blobCache;
      //         var base64 = reader.result.split(",")[1];
      //         var blobInfo = blobCache.create(id, file, base64);
      //         blobCache.add(blobInfo);
      //         /* call the callback and populate the Title field with the file name */
      //         cb(blobInfo.blobUri(), { title: file.name });
      //       };
      //       reader.readAsDataURL(file);
      //     };
      //     input.click();
      //   }
      // },
      blogData: {
        title: "",
        author: "",
        blogType: "",
        blogPosition: "",
        homeBlog: false,
        shortDesc: "",
        description1: "",
        description2: "",
        cardImage: [],
        detailImage: [],
        tag: [],
        urlSlug: "",
        status: "",
        blogDate: "",
        decsUrl: ""
      },
      cardImg: [],
      detailImg: [],
      blogType: [
        { text: "Featured", value: "featured" },
        { text: "Popular", value: "popular" },
        { text: "Archive", value: "archive" }
      ],
      status: [
        { text: "Enabled", value: "enabled" },
        { text: "Disabled", value: "disabled" }
      ],
      tags: [],
      showError: false,
      message: ""
    };
  },
  validations: {
    blogData: {
      title: {
        required
      },
      //   author: {
      //     required
      //   },
      blogType: {
        required
      },
      shortDesc: {
        required
      },
      urlSlug: {
        required
      },
      status: {
        required
      },
      description1: {
        required
      },
      //   detailImage: {
      //     required
      //   },
      cardImage: {
        required
      },
      blogDate: {
        required
      }
    }
  },

  created() {
    service.getAllHotelName(result => {
      this.tags = result.data;
    });
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.blogData[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.$router.push({ name: "viewBlogList" });
    },
    saveBlogs(data) {
      this.$v.blogData.$touch();
      if (this.$v.blogData.$anyError) {
        return;
      } else if (this.blogData.description1 == "") {
        this.showError = true;
        this.message = `Blog type ${this.blogData.blogType} with Blog Position ${this.blogData.blogPosition} already defined.`;
        this.$toaster.error(
          `Blog type ${this.blogData.blogType} with Blog Position ${this.blogData.blogPosition} already defined.`
        );
      } else if (this.blogData.blogPosition && this.blogData.blogType) {
        var blogs = {};
        blogs.position = this.blogData.blogPosition;
        blogs.blogType = this.blogData.blogType;
        service.checkBlogPosition(blogs, result => {
          if (result.status == 200) {
            this.showError = true;
            this.message = `Blog type ${this.blogData.blogType} with Blog Position ${this.blogData.blogPosition} already defined.`;
            this.$toaster.error(
              `Blog type ${this.blogData.blogType} with Blog Position ${this.blogData.blogPosition} already defined.`
            );
            return;
          } else {
            return new Promise((resolve, reject) => {
              service.saveBlogs(this.blogData, resultData => {
                if (resultData) {
                  this.$toaster.success("Blogs saved");
                  this.$router.push({ name: "viewBlogList" });
                }
              });
            });
          }
        });
      } else {
        return new Promise((resolve, reject) => {
          service.saveBlogs(this.blogData, resultData => {
            if (resultData) {
              this.$toaster.success("Blogs saved");
              this.$router.push({ name: "viewBlogList" });
            }
          });
        });
      }
    },
    removeCard(image) {
      this.cardImg.pop(image);
    },
    removeDetail(image) {
      this.detailImg.pop(image);
    },
    onCardChange(coverName, coverList) {
      if (this.cardImg.length >= 1) {
        this.$toaster.error("Maximum limit of selection of  image is 1");
      } else {
        this.coverFile = coverList[0];
        const coverSize = this.coverFile.size / 1024;
        if (coverSize > 1000) {
          //cover ka 500kb
          this.errorDialog = true;
          this.errorText = "File Size should not be greater than 1mb.";
          this.$toaster.error("File Size should not be greater than 1mb.");
          return;
        }
        if (this.coverFile.type == "image/jpeg" || this.coverFile.type == "image/jpg") {
          const fd = new FormData();
          fd.append("file", this.coverFile, this.coverFile.name);
          service.awscoverImageUpload(fd, awsResponse => {
            if (awsResponse.status === 200) {
              const predictionResults = awsResponse.data;
              this.s3URL = predictionResults.file;
              this.cardImg.push(this.s3URL);
              this.blogData.cardImage = this.cardImg;
            } else {
              this.$toaster.error("Card Image Not Saved");
            }
          });
        } else {
          this.$toaster.error("Image should be in JPEG format only");
        }
      }
    },
    onDetailChange(coverName, coverList) {
      if (this.detailImg.length >= 1) {
        this.$toaster.error("Maximum limit of selection of  image is 1");
      } else {
        this.coverFile = coverList[0];
        const coverSize = this.coverFile.size / 1024;
        if (coverSize > 1000) {
          //cover ka 500kb
          this.errorDialog = true;
          this.errorText = "File Size should not be greater than 1mb.";
          this.$toaster.error("File Size should not be greater than 1mb.");
          return;
        }
        if (this.coverFile.type == "image/jpeg" || this.coverFile.type == "image/jpg") {
          const fd = new FormData();
          fd.append("file", this.coverFile, this.coverFile.name);
          service.awscoverImageUpload(fd, awsResponse => {
            if (awsResponse.status === 200) {
              const predictionResults = awsResponse.data;
              this.s3URL = predictionResults.file;
              this.detailImg.push(this.s3URL);
              this.blogData.detailImage = this.detailImg;
            } else {
              this.$toaster.error("Cover Image Not Saved");
            }
          });
        } else {
          this.$toaster.error("Image should be in JPEG format only");
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";
.blog-section {
  .blog-content {
    padding: 20px 20px;
  }
}
.error-text {
  font-size: 13px;
  color: #dc3545;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
